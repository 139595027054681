import * as React from 'react'
import { mdiLockOutline, mdiMapMarkerQuestionOutline, mdiAlertCircleOutline } from '@mdi/js'
import { Box, Container, Flex, Heading, Image, Text } from '@chakra-ui/react'

export interface GenericErrorTemplateProps {
  /** The title of the page */
  title: string
  /** Optional subtext of the page */
  subtext?: string
  /** Main error text */
  text: React.ReactNode | string
  /** Links */
  shortcuts?: React.ReactNode
  /** Variant types */
  variant: 'not-found' | 'access-denied' | 'error' | 'unauthorized'
}

export const variants = {
  'not-found': {
    color: 'purple',
    icon: mdiMapMarkerQuestionOutline,
    illustration: {
      colored: 'https://sitecorecontenthub.stylelabs.cloud/api/public/content/d5bf9fd27c404416953020f5f832aaa5',
      grayed: 'https://sitecorecontenthub.stylelabs.cloud/api/public/content/294e705894f743a5999016228246b201'
    },
    label: 'Resource not found'
  },
  'access-denied': {
    color: 'orange',
    icon: mdiLockOutline,
    illustration: {
      colored: 'https://sitecorecontenthub.stylelabs.cloud/api/public/content/84204dfa20564898b4fce871fa0bddde',
      grayed: 'https://sitecorecontenthub.stylelabs.cloud/api/public/content/a25e8ad53e9f4a608c568962cea62c8c'
    },
    label: 'Access denied'
  },
  error: {
    color: 'red',
    icon: mdiAlertCircleOutline,
    illustration: {
      colored: 'https://sitecorecontenthub.stylelabs.cloud/api/public/content/b6711ebbf606492d91308cfe28a654e3',
      grayed: 'https://sitecorecontenthub.stylelabs.cloud/api/public/content/7fd8d96f6bf6481b8f27e153c167bcd2'
    },
    label: 'Error'
  },
  unauthorized: {
    color: 'red',
    icon: mdiAlertCircleOutline,
    illustration: {
      colored: 'https://sitecorecontenthub.stylelabs.cloud/api/public/content/a6f5b0e650f64cdc9b2cd97a9f66712a',
      grayed: 'https://sitecorecontenthub.stylelabs.cloud/api/public/content/b68252dc9287439281a8eba651741d1f'
    },
    label: 'Unauthorized'
  },
  unavailable: {
    color: 'red',
    icon: mdiAlertCircleOutline,
    illustration: {
      colored: 'https://sitecorecontenthub.stylelabs.cloud/api/public/content/903645789c9649029df8cb32d44668c0',
      grayed: 'https://sitecorecontenthub.stylelabs.cloud/api/public/content/b8149b6a41c446e3978c196de3032442'
    },
    label: 'Error'
  }
}

const GenericErrorTemplate: React.FC<GenericErrorTemplateProps> = ({ title, subtext, text, variant, shortcuts }) => {
  const activeVariant = variants[variant]

  return (
    <Container pt={9} py={8} pb={12} height='100%' width='100%'>
      <Container maxW='sm' width='sm' pt={24}>
        <Flex justify='center' flexDirection='column' alignItems='center'>
          <Box borderRadius='2xl' display='flex' alignItems='center' justifyContent='center'>
            <Image width={28} height={28} src={activeVariant.illustration.colored} alt={activeVariant.label} />
          </Box>
          <Heading textAlign='center' pt='8' size='xl' as='h2'>
            {title}
          </Heading>
          <Text textAlign='center' variant='subtle' pt='2'>
            {subtext}
          </Text>
          <Text textAlign='center' py='6' size='md' color='gray.800'>
            {text}
          </Text>
          {shortcuts && (
            <Box display='flex' flexDirection='column' alignItems='center'>
              {shortcuts}
            </Box>
          )}
        </Flex>
      </Container>
    </Container>
  )
}

export { GenericErrorTemplate }

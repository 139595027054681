import { Box, Button, Flex, Icon, Link, VStack } from '@chakra-ui/react'
import { mdiAlertOutline } from '@mdi/js'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { GenericErrorTemplate } from './GenericErrorTemplate.js'

const getVariant = (e: Error) => {
  switch (e.name) {
    case 'AuthError':
      return 'unauthorized'
    case 'TenantError':
      return 'access-denied'
    case 'LibraryError':
      return 'access-denied'
    default:
      return 'error'
  }
}
const Error = ({ error, resetErrorBoundary }: { error: Error; resetErrorBoundary: () => void }) => {
  const navigate = useNavigate()
  useEffect(() => {
    if (error) {
      ;(document.querySelector('feaas-context')?.shadowRoot || document)
        ?.querySelector('feaas-loader-app, feaas-loader')
        ?.setAttribute('hidden', 'hidden')

      if ('Cypress' in window) {
        try {
          const e = error.cause || error
          // @ts-ignore
          window.Cypress.log(e.message, e.stack)
        } catch (e) {}
      }
    }
  }, [error])
  console.log(error.cause)
  return (
    <Flex height={'100%'} justifyContent={'center'} alignItems={'center'}>
      <VStack>
        <GenericErrorTemplate
          title={error.message}
          text={'Try to logout and login again or contact your organization admin'}
          variant={getVariant(error)}
          shortcuts={
            <Link
              fontWeight={600}
              color={'primary.600'}
              onClick={() => {
                navigate('/logout')
                location.reload()
              }}
              mb={4}
            >
              Logout
            </Link>
          }
        />
      </VStack>
    </Flex>
  )
}

export default Error

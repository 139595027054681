import { DatasourceModel, ExternalComponent, ExternalComponentModel } from '@sitecore-feaas/sdk'
import MessageReceiverIframe from './MessageReceiverIframe.js'
import React, { useEffect, useState } from 'react'
import { useLibrary, useSDK } from '../hooks/useData.js'

const ByocRegistration = () => {
  const sdk = useSDK()
  const renderingHost = useSDK('renderingHost')
  const library = useLibrary()
  const registeredComponents = useSDK('renderingHost.registeredComponents')
  const expandedComponents = useSDK('renderingHost.expandedComponents')
  const registeredDatasources = useSDK('renderingHost.registeredDatasources')
  const [url, setUrl] = useState(library.getPreviewURL())

  // load url once rendering host fetching is done
  useEffect(() => {
    if (renderingHost.url) setUrl(library.getPreviewURL())
  }, [renderingHost.url, library.settings.externalPreviewURL])

  if (!url) return

  return (
    <MessageReceiverIframe
      url={url}
      onMessageWaiting={() => {
        renderingHost.set({ connectionStatus: 'loading' })
      }}
      onMessageReceived={(message) => {
        if (message.action === 'register-components') {
          const components = message.data.map((item: ExternalComponent) => ({
            id: item.name,
            ...item
          }))
          ExternalComponentModel.setComponents(components)
          ExternalComponentModel.updateComponents(registeredComponents, expandedComponents)
          sdk.log('FEAAS: Registered external components %O', registeredComponents)
          renderingHost.set({ connectionStatus: 'success' })
        } else if (message.action === 'register-datasources') {
          const datasources = message.data.map((d: any) => ({
            ...d,
            sample: d.sample || (d.schema ? DatasourceModel.schema.generate(d.schema) : null)
          }))
          DatasourceModel.setExternalDatasources(datasources)
          DatasourceModel.updateExternalDatasources(registeredDatasources)
          sdk.log('FEAAS: Registered external datasources %O', registeredDatasources)
          renderingHost.set({ connectionStatus: 'success' })
        }
      }}
      onTimeout={() => {
        if (renderingHost.connectionStatus === 'loading') {
          setUrl(library.getFallbackPreviewURL())
          /**
           * that's a second timeout in case the first finishes with no message,
           * wait same amount of time and if still no message, set status error
           */
          setTimeout(() => {
            if (renderingHost.connectionStatus === 'loading') {
              renderingHost.set({ connectionStatus: 'error' })
            }
          }, 10000)
        }
      }}
      timeout={10000}
    />
  )
}

export default ByocRegistration

import { BadgeProps, extendTheme, Tooltip } from '@chakra-ui/react'
import { tinycolor } from '@ctrl/tinycolor'
import { mergeDeep } from '@sitecore-feaas/sdk'
import { theme as defaultTheme, ChakraTheme } from '@chakra-ui/theme'
import './shared.types.js'

Tooltip.defaultProps = {
  placement: 'top',
  hasArrow: true
}
export const sitecoreTheme = extendTheme({
  zIndexes: {
    navbar: 200,
    activeEntity: 100,
    editingOverlay: 50,
    menu: 90
  },
  styles: {
    global: {
      // FEAAS HACK: Undo body styles to avoid messing up with pages.
      // These are then set as [feaas-role=ui]
      body: {
        bg: null,
        color: null,
        fontSize: null,
        lineHeight: null,
        fontFamily: null
      },
      strong: {
        fontWeight: '600'
      }
    }
  },
  colors: {
    primary: {
      50: tinycolor(tinycolor('FAF5FF').mix('EBF8FF', 24)).saturate(20).toHexString(),
      100: tinycolor(tinycolor(tinycolor('E9D8FD').mix('BEE3F8', 24)).tint(25).saturate(20)).toHexString(),
      200: tinycolor(tinycolor('D6BCFA').mix('90CDF4', 24)).saturate(20).toHexString(),
      300: tinycolor(tinycolor('B794F4').mix('63B3ED', 24)).saturate(20).toHexString(),
      400: tinycolor(
        tinycolor(tinycolor('9F7AEA').mix('4299E1', 24)).mix(tinycolor('805AD5').mix('3182CE', 24), 70).saturate(20)
      ).toHexString(),
      500: '#5548d9',
      600: tinycolor(tinycolor('6B46C1').mix('2B6CB0', 24)).saturate(20).toHexString(),
      700: tinycolor(tinycolor('553C9A').mix('2C5282', 24)).saturate(20).toHexString(),
      800: tinycolor(tinycolor('44337A').mix('2A4365', 24)).saturate(20).toHexString(),
      900: tinycolor(tinycolor('322659').mix('1A365D', 24)).saturate(20).toHexString()
    },
    // primary: {
    //   50: '#F6F6FF',
    //   100: '#E3E0FF',
    //   200: '#C0BAFF',
    //   300: '#9A90FD',
    //   400: '#665AEC' /* tweaked for a11y */,
    //   500: '#5548D9' /* original brand color */,
    //   600: '#4A37D5',
    //   700: '#3B2CA9',
    //   800: '#312686',
    //   900: '#201D67',
    // },
    // gray: {
    //   /* neutral untinted gray scale */
    //   50: tinycolor('FAFAFA').mix('F5F5F5').toHexString(),
    //   100: tinycolor('F5F5F5').mix('E5E5E5').toHexString(),
    //   200: '#E5E5E5',
    //   300: '#D4D4D4',
    //   400: '#A3A3A3',
    //   500: '#737373',
    //   600: '#525252',
    //   700: '#404040',
    //   800: '#262626',
    //   900: '#171717',
    // },
    gray: {
      /* new & improved slightly tinted gray scale */

      50: tinycolor('FAFAFA').mix('F4F4F5').toHexString(),
      100: tinycolor('F4F4F5').mix('E4E4E7').toHexString(),
      200: '#E4E4E7',
      300: '#D4D4D8',
      400: '#94949D' /* adjusted for 3:1 contrast ratio */,
      500: '#71717A',
      600: '#52525B',
      700: '#3F3F46',
      800: '#27272A',
      900: '#18181B'
    },
    blackAlpha: {
      50: 'rgba(0,0,0,0.03)',
      100: 'rgba(0,0,0,0.07)',
      200: 'rgba(0,0,0,0.10)',
      300: 'rgba(0,0,0,0.17)',
      400: 'rgba(0,0,0,0.42)' /* adjusted for 3:1 contrast ratio */,
      500: 'rgba(0,0,0,0.55)',
      600: 'rgba(0,0,0,0.68)',
      700: 'rgba(0,0,0,0.75)',
      800: 'rgba(0,0,0,0.85)',
      900: 'rgba(0,0,0,0.91)'
    },
    whiteAlpha: {
      50: 'rgba(255,255,255,0.03)',
      100: 'rgba(255,255,255,0.07)',
      200: 'rgba(255,255,255,0.10)',
      300: 'rgba(255,255,255,0.17)',
      400: 'rgba(255,255,255,0.42)',
      500: 'rgba(255,255,255,0.55)',
      600: 'rgba(255,255,255,0.68)',
      700: 'rgba(255,255,255,0.75)',
      800: 'rgba(255,255,255,0.85)',
      900: 'rgba(255,255,255,0.91)'
    },
    red: {
      100: tinycolor('FED7D7').tint(25).toHexString()
    },
    orange: {
      100: tinycolor('FEEBC8').tint(25).toHexString()
    },
    yellow: {
      100: tinycolor('FEFCBF').tint(25).toHexString()
    },
    green: {
      100: tinycolor('C6F6D5').tint(25).toHexString()
    },
    teal: {
      50: tinycolor('E6FFFA').spin(1).toHexString(),
      100: tinycolor(tinycolor('B2F5EA').spin(1)).tint(25).toHexString(),
      200: tinycolor('81E6D9').spin(1).toHexString(),
      300: tinycolor('4FD1C5').spin(1).toHexString(),
      400: tinycolor('38B2AC').spin(1).toHexString(),
      500: '#02999A',
      600: tinycolor('2C7A7B').spin(1).toHexString(),
      700: tinycolor('285E61').spin(1).toHexString(),
      800: tinycolor('234E52').spin(1).toHexString(),
      900: tinycolor('1D4044').spin(1).toHexString()
    },
    cyan: {
      100: tinycolor('C4F1F9').tint(20).toHexString(),
      500: tinycolor('00B5D8').shade(20).toHexString(),
      600: tinycolor('00A3C4').shade(20).toHexString(),
      700: tinycolor('0987A0').shade(20).toHexString(),
      800: tinycolor('086F83').shade(20).toHexString(),
      900: tinycolor('065666').shade(20).toHexString()
    },
    blue: {
      100: tinycolor('BEE3F8').tint(25).toHexString()
    },
    purple: {
      50: tinycolor(tinycolor('FAF5FF').mix('EBF8FF', 24)).saturate(20).toHexString(),
      100: tinycolor(tinycolor(tinycolor('E9D8FD').mix('BEE3F8', 24)).tint(25).saturate(20)).toHexString(),
      200: tinycolor(tinycolor('D6BCFA').mix('90CDF4', 24)).saturate(20).toHexString(),
      300: tinycolor(tinycolor('B794F4').mix('63B3ED', 24)).saturate(20).toHexString(),
      400: tinycolor(
        tinycolor(tinycolor('9F7AEA').mix('4299E1', 24)).mix(tinycolor('805AD5').mix('3182CE', 24), 70).saturate(20)
      ).toHexString(),
      500: tinycolor(tinycolor('805AD5').mix('3182CE', 24)).saturate(20).toHexString(),
      600: tinycolor(tinycolor('6B46C1').mix('2B6CB0', 24)).saturate(20).toHexString(),
      700: tinycolor(tinycolor('553C9A').mix('2C5282', 24)).saturate(20).toHexString(),
      800: tinycolor(tinycolor('44337A').mix('2A4365', 24)).saturate(20).toHexString(),
      900: tinycolor(tinycolor('322659').mix('1A365D', 24)).saturate(20).toHexString()
    },
    pink: {
      100: tinycolor('FED7E2').tint(25).toHexString()
    }
  },
  shadows: {
    outline: '0 0 0 3px rgba(85, 72, 217, 0.5)',
    stroke: '0 0 0 1px rgba(0,0,0,0.10)'
  },
  fontSizes: {
    xs: '0.6875rem',
    sm: '0.75rem',
    md: '0.875rem',
    lg: '1rem',
    xl: '1.125rem',
    '2xl': '1.25rem',
    '3xl': '1.5rem',
    '4xl': '1.875rem',
    '5xl': '2.25rem',
    '6xl': '3rem',
    '7xl': '3.75rem',
    '8xl': '4.5rem',
    '9xl': '6rem'
  },
  sizes: {
    4.5: '1.125rem',
    15: '3.75rem'
  },
  radii: {
    none: '0',
    sm: '0.125rem',
    base: '0.1875rem',
    md: '0.25rem',
    lg: '0.375rem',
    xl: '0.5rem',
    '2xl': '0.75rem',
    '3xl': '1rem'
  },
  semanticTokens: {
    colors: {
      'chakra-body-bg': {
        _light: 'white',
        _dark: 'gray.800'
      },
      'chakra-body-text': {
        _light: 'blackAlpha.800',
        _dark: 'whiteAlpha.900'
      },
      'chakra-border-color': {
        _light: 'blackAlpha.200',
        _dark: 'whiteAlpha.300'
      },
      'chakra-placeholder-color': {
        _light: 'blackAlpha.500',
        _dark: 'whiteAlpha.500'
      },
      'primary-color': {
        _light: 'primary.500',
        _dark: 'primary.300'
      },
      'border-color-subtle': {
        _light: 'blackAlpha.100',
        _dark: 'whiteAlpha.200'
      },
      'border-color-a11y': {
        _light: 'blackAlpha.400',
        _dark: 'whiteAlpha.400'
      },
      'border-color-a11y-hover': {
        _light: 'blackAlpha.600',
        _dark: 'whiteAlpha.500'
      }
    }
  },
  layerStyles: {
    bg: {
      white: { bg: 'white' },
      light: { bg: 'gray.50' },
      medium: { bg: 'gray.100' },
      dark: { bg: 'gray.200' }
    },
    sidebar: {
      white: { bg: 'white' },
      light: { bg: 'gray.50' },
      medium: { bg: 'gray.100' },
      dark: { bg: 'gray.200' }
    },
    footer: {
      white: { bg: 'white' },
      light: { bg: 'gray.50' },
      medium: { bg: 'gray.100' },
      dark: { bg: 'gray.200' }
    },
    light: {
      rounded: 'md',
      bg: 'gray.50',
      inset: {
        bg: 'gray.50',
        shadow: 'inner'
      }
    },
    medium: {
      rounded: 'md',
      bg: 'gray.100',
      inset: {
        bg: 'gray.100',
        shadow: 'inner'
      }
    },
    transparent: {
      rounded: 'md',
      bg: 'transparent',
      interactive: {
        transitionProperty: 'common',
        transitionDuration: 'normal',
        rounded: 'md',
        bg: 'transparent',
        _hover: { bg: 'blackAlpha.100' },
        _active: { bg: 'blackAlpha.200' }
      },
      selected: {
        transitionProperty: 'common',
        transitionDuration: 'normal',
        rounded: 'md',
        bg: 'primary.100',
        _active: { bg: 'primary.200' }
      }
    },
    outline: {
      rounded: 'md',
      bg: 'white',
      border: '1px solid',
      borderColor: 'chakra-border-color',
      interactive: {
        transitionProperty: 'common',
        transitionDuration: 'normal',
        border: '1px solid',
        borderColor: 'chakra-border-color',
        rounded: 'md',
        bg: 'white',
        _hover: { bg: 'gray.100' },
        _active: { bg: 'gray.100' }
      },
      selected: {
        transitionProperty: 'common',
        transitionDuration: 'normal',
        border: '1px solid',
        borderColor: 'chakra-border-color',
        rounded: 'md',
        bg: 'primary.100',
        _active: { bg: 'primary.200' }
      }
    },
    outline2: {
      rounded: 'md',
      bg: 'white',
      border: '1px solid',
      borderColor: 'chakra-border-color',
      interactive: {
        transitionProperty: 'common',
        transitionDuration: 'normal',
        border: '1px solid',
        borderColor: 'chakra-border-color',
        rounded: 'md',
        bg: 'white',
        _hover: {
          shadow: 'md'
        },
        _active: {
          bg: 'gray.200',
          shadow: 'base'
        }
      },
      selected: {
        transitionProperty: 'common',
        transitionDuration: 'normal',
        border: '1px solid',
        borderColor: 'chakra-border-color',
        rounded: 'md',
        bg: 'primary.100',
        _hover: { shadow: 'md' },
        _active: { bg: 'primary.200', shadow: 'base' }
      }
    },
    outline3: {
      rounded: 'md',
      bg: 'white',
      border: '1px solid',
      borderColor: 'chakra-border-color',
      interactive: {
        transitionProperty: 'common',
        transitionDuration: 'normal',
        border: '1px solid',
        borderColor: 'chakra-border-color',
        rounded: 'md',
        bg: 'white',
        _hover: { bg: 'gray.100' },
        _active: { bg: 'gray.200' }
      },
      selected: {
        transitionProperty: 'common',
        transitionDuration: 'normal',
        border: '1px solid',
        borderColor: 'primary.500',
        rounded: 'md',
        bg: 'white',
        _hover: { bg: 'gray.100' },
        _active: { bg: 'gray.200' }
      }
    },
    shadow: {
      rounded: 'md',
      bg: 'white',
      shadow: 'base',
      interactive: {
        transitionProperty: 'common',
        transitionDuration: 'normal',
        rounded: 'md',
        bg: 'white',
        shadow: 'base',
        _hover: {
          shadow: 'md'
        },
        _active: {
          bg: 'gray.200',
          shadow: 'base'
        }
      },
      selected: {
        transitionProperty: 'common',
        transitionDuration: 'normal',
        rounded: 'md',
        bg: 'primary.100',
        shadow: 'base',
        _hover: {
          shadow: 'md'
        },
        _active: { bg: 'primary.200', shadow: 'base' }
      }
    },
    white: {
      rounded: 'md',
      bg: 'white',
      interactive: {
        transitionProperty: 'common',
        transitionDuration: 'normal',
        rounded: 'md',
        bg: 'white',
        _hover: {
          shadow: 'md'
        },
        _active: {
          bg: 'gray.200',
          shadow: 'base'
        }
      },
      selected: {
        transitionProperty: 'common',
        transitionDuration: 'normal',
        rounded: 'md',
        bg: 'primary.100',
        _hover: {
          shadow: 'md'
        },
        _active: { bg: 'primary.200', shadow: 'base' }
      }
    },
    grayLight: {},
    gray: {},
    swatch: { minW: '8', maxW: '3xs', h: '8', rounded: 'md' }
  },
  components: {
    Alert: {
      baseStyle: {
        container: {
          rounded: 'md'
        },
        title: {
          fontWeight: 'semibold'
        }
      }
    },
    Badge: {
      baseStyle: {
        fontSize: 'sm'
      },
      defaultProps: {
        colorScheme: 'blackAlpha'
      }
    },
    Breadcrumb: {
      baseStyle: {
        link: {
          color: 'chakra-placeholder-color'
        },
        separator: {
          color: 'border-color-a11y'
        }
      }
    },
    Button: {
      baseStyle: {
        rounded: 'full',
        _hover: {
          _disabled: {
            bg: null
          }
        }
      },
      variants: {
        solid: {
          defaultProps: {
            colorScheme: 'primary'
          }
        },
        primary: {
          color: 'white',
          bg: 'primary.500',
          _hover: {
            bg: 'primary.600'
          },
          _active: {
            bg: 'primary.700'
          }
        },
        secondary: {
          border: '1px solid',
          borderColor: 'chakra-border-color',
          color: 'blackAlpha.600',
          _hover: {
            bg: 'blackAlpha.100'
          },
          _active: {
            bg: 'blackAlpha.200'
          }
        },
        subtle: {
          color: 'primary.600',
          _hover: {
            bg: 'blackAlpha.100'
          },
          _active: {
            bg: 'primary.100'
          }
        },
        minimal: {
          color: 'blackAlpha.600',
          _hover: {
            bg: 'blackAlpha.100'
          },
          _active: {
            bg: 'blackAlpha.200'
          }
        },
        navHorizontal: {
          px: '2',
          color: 'blackAlpha.600',
          rounded: 'md',
          _hover: {
            bg: 'blackAlpha.100'
          },
          _active: {
            bg: 'blackAlpha.200'
          }
        },
        navHorizontalActive: {
          px: '2',
          color: 'primary.600',
          rounded: 'md',
          bg: 'primary.100',
          _active: {
            bg: 'primary.200'
          }
        },
        navVertical: {
          px: '3',
          justifyContent: 'none',
          color: 'blackAlpha.600',
          rounded: 'md',
          _hover: {
            bg: 'blackAlpha.100'
          },
          _active: {
            bg: 'blackAlpha.200'
          }
        },
        navVerticalActive: {
          px: '3',
          justifyContent: 'none',
          color: 'primary.600',
          rounded: 'md',
          bg: 'primary.100',
          _active: {
            bg: 'primary.200'
          }
        },
        filter: {
          px: '3',
          color: 'blackAlpha.600',
          rounded: 'md',
          border: '1px solid',
          borderColor: 'chakra-border-color',
          _hover: {
            bg: 'blackAlpha.100'
          },
          _active: {
            bg: 'blackAlpha.200'
          }
        },
        filterActive: {
          px: '3',
          pr: '1',
          color: 'primary.600',
          rounded: 'md',
          bg: 'primary.100',
          _active: {
            bg: 'primary.200'
          }
        }
      },
      sizes: {
        lg: {
          fontSize: 'md'
        },
        sm: {
          fontSize: 'md'
        },
        xs: {
          fontSize: 'sm'
        }
      }
    },
    CloseButton: {
      baseStyle: {
        rounded: 'full'
      },
      sizes: {
        lg: {
          fontSize: 'md'
        }
      }
    },
    Checkbox: {
      baseStyle: {
        control: {
          borderColor: 'border-color-a11y',
          bg: 'white',
          _disabled: {
            bg: 'blackAlpha.200',
            borderColor: 'transparent'
          },
          _checked: {
            _disabled: {
              bg: 'blackAlpha.200',
              borderColor: 'transparent',
              color: 'chakra-placeholder-color'
            }
          }
        }
      },
      defaultProps: {
        colorScheme: 'primary'
      },
      sizes: {
        lg: {
          label: { fontSize: 'md' }
        }
      }
    },
    CircularProgress: {},
    Code: { defaultProps: { colorScheme: 'blackAlpha' } },
    Divider: {
      baseStyle: {
        borderColor: 'chakra-border-color',
        opacity: '1'
      }
    },
    Heading: {
      baseStyle: {
        fontWeight: 'semibold'
      },
      variants: {
        section: {
          color: 'chakra-placeholder-color',
          textTransform: 'uppercase',
          fontSize: 'sm'
        }
      },
      sizes: {
        '4xl': {
          fontSize: ['5xl', '6xl', '7xl'],
          lineHeight: [1]
        },
        '3xl': {
          fontSize: ['4xl', '5xl', '6xl'],
          lineHeight: [1.2, 1]
        },
        '2xl': {
          fontSize: ['3xl', '4xl', '5xl'],
          lineHeight: [1.2, 1.2, 1]
        },
        xl: {
          fontSize: ['2xl', '3xl', '4xl'],
          lineHeight: [1.33, 1.2, 1.2]
        },
        lg: {
          fontSize: ['xl', '2xl', '3xl'],
          lineHeight: [1.33, 1.33, 1.2]
        },
        sm: {
          fontSize: 'lg'
        }
      }
    },
    Icon: {
      defaultProps: {
        boxSize: '6'
      }
    },
    Input: {
      variants: {
        outline: {
          field: {
            borderColor: 'border-color-a11y',
            bg: 'white',
            _hover: {
              borderColor: 'border-color-a11y-hover'
            }
          },
          addon: {
            borderColor: 'border-color-a11y'
          }
        }
      },
      defaultProps: {
        focusBorderColor: 'primary.500'
      },
      sizes: {
        sm: {
          field: {
            fontSize: 'md',
            borderRadius: 'md'
          },
          addon: {
            borderRadius: 'md',
            fontSize: 'md'
          }
        }
      }
    },
    Link: {
      baseStyle: {
        color: 'primary.400'
      }
    },
    Modal: {
      defaultProps: {
        isCentered: true,
        scrollBehavior: 'inside'
      },
      baseStyle: {
        dialog: { rounded: 'xl' },
        header: { py: '6', px: '8' },
        body: { px: '8' },
        footer: { py: '6', px: '6' },
        closeButton: { top: '3', insetEnd: '3' },
        overlay: {
          bg: 'blackAlpha.500'
        }
      }
    },
    NumberInput: {
      variants: {
        outline: {
          field: {
            borderColor: 'border-color-a11y',
            bg: 'white',
            _hover: {
              borderColor: 'border-color-a11y-hover'
            }
          }
        }
      },
      defaultProps: {
        focusBorderColor: 'primary.500'
      },
      sizes: {
        sm: {
          field: {
            fontSize: 'md',
            borderRadius: 'md'
          }
        }
      }
    },
    Radio: {
      baseStyle: {
        control: {
          borderColor: 'border-color-a11y',
          bg: 'white',
          _disabled: {
            bg: 'blackAlpha.200',
            borderColor: 'transparent'
          },
          _checked: {
            _disabled: {
              bg: 'blackAlpha.200',
              borderColor: 'transparent',
              color: 'blackAlpha.500'
            }
          }
        }
      },
      defaultProps: {
        colorScheme: 'primary'
      },
      sizes: {
        lg: {
          label: { fontSize: 'md' }
        }
      }
    },
    Select: {
      variants: {
        outline: {
          field: {
            borderColor: 'border-color-a11y',
            bg: 'white',
            _hover: {
              borderColor: 'border-color-a11y-hover'
            }
          }
        }
      },
      defaultProps: {
        focusBorderColor: 'primary.500'
      }
    },
    Skeleton: {
      defaultProps: {
        startColor: 'blackAlpha.100',
        endColor: 'blackAlpha.300'
      }
    },
    Spinner: {
      baseStyle: {
        color: 'primary.500'
      }
    },
    Switch: {
      baseStyle: {
        track: {
          bg: 'border-color-a11y',
          _checked: {
            bg: 'teal'
          }
        }
      },
      defaultProps: {
        colorScheme: 'teal'
      }
    },
    Table: {
      baseStyle: {
        tr: {
          th: {
            fontSize: 'sm',
            fontWeight: 'semibold',
            textTransform: 'none',
            color: 'chakra-placeholder-color',
            letterSpacing: 'normal',
            borderColor: 'border-color-subtle'
          },
          td: {
            borderColor: 'border-color-subtle'
          }
        }
      },
      variants: {
        striped: {
          tr: {
            th: {
              borderColor: 'none'
            },
            td: {
              borderColor: 'none'
            }
          }
        }
      }
    },
    Tabs: {
      defaultProps: {
        colorScheme: 'primary'
      },
      variants: {
        line: {
          tablist: {
            borderColor: 'chakra-border-color'
          },
          tab: {
            fontWeight: 'semibold'
          }
        },
        'soft-rounded': {
          tab: {
            color: 'blackAlpha.600'
          }
        },
        'solid-rounded': {
          tab: {
            color: 'blackAlpha.600'
          }
        }
      }
    },
    Tag: {
      defaultProps: {
        colorScheme: 'blackAlpha'
      },
      // baseStyle: {
      //   container: {
      //     fontWeight: 'normal',
      //   },
      // },
      sizes: {
        sm: {
          container: {
            fontSize: 'sm',
            px: '1.5'
          }
        },
        md: {
          container: {
            fontSize: 'md'
          }
        },
        lg: {
          container: {
            fontSize: 'md'
          }
        }
      },
      variants: {
        filter: {
          container: {
            backgroundColor: 'primary.100'
          }
        }
      }
    },
    Text: {
      variants: {
        subtle: {
          color: 'chakra-placeholder-color'
        },
        small: {
          color: 'chakra-placeholder-color',
          fontSize: 'sm'
        },
        tiny: {
          color: 'chakra-placeholder-color',
          fontSize: 'xs'
        },
        label: {
          color: 'chakra-placeholder-color',
          fontWeight: 'semibold'
        },
        labelsm: {
          color: 'chakra-placeholder-color',
          // fontSize: 'sm',
          fontWeight: 'semibold'
          // textTransform: 'uppercase',
        }
      }
    },
    Textarea: {
      baseStyle: {
        maxW: 'lg'
      },
      variants: {
        outline: {
          borderColor: 'border-color-a11y',
          bg: 'white',
          _hover: {
            borderColor: 'border-color-a11y-hover'
          }
        }
      },
      defaultProps: {
        focusBorderColor: 'primary.500'
      }
    }
  }
})

const FEAASStyles = {
  zIndexes: {
    navbar: 200,
    activeEntity: 100,
    editingOverlay: 50,
    menu: 90
  },
  fontSizes: {
    'icon-xl': '24px',
    'icon-md': '16px',
    'icon-lg': '18px',
    'icon-sm': '14px'
  },
  sizes: {
    'icon-xl': '24px',
    'icon-md': '16px',
    'icon-lg': '18px',
    'icon-sm': '14px',
    container: {
      xxl: '1440px'
    }
  },
  styles: {
    global: {
      '.has-edited-entity #layout .entity-is-active .entity-is-active': { outline: 'none' },
      '.has-edited-entity #layout *': {
        pointerEvents: 'none !important'
      },
      '#overflow-wrapper': {
        transition: '.5s padding-bottom, .5s scroll-margin'
      },
      '.has-edited-entity #layout #navbar:before': {
        content: '""',
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
        background: 'white',
        position: 'absolute',
        opacity: 0.5,
        display: 'block',
        zIndex: 2
      },
      '.has-active-entity #layout .entity-is-active': {
        position: 'relative',
        zIndex: 1
      },
      '.has-edited-entity #layout .entity-is-active': {
        outline: '20000px solid rgba(255,255,255,0.5)',
        position: 'relative',
        zIndex: 1
      },
      '.has-edited-entity #layout .entity-is-active *': {
        pointerEvents: 'all !important'
      },
      '.chakra-collapse[style*="height: auto"]': {
        overflow: 'initial !important'
      },
      '.chakra-popover__popper.chakra-popover__popper,  .chakra-menu__menu-button.chakra-menu__menu-button + div:not([class]),  .chakra-menu__menu-button.chakra-menu__menu-button + span + div, .chakra-menu__menu-button + [style*="--popper-transform-origin"]':
        {
          margin: '0'
        },
      '[feaas-role="ui"] label': {
        userSelect: 'none'
      },
      '.chakra-icon path': {
        fill: 'currentColor'
      },
      '.chakra-portal': {
        position: 'relative'
      },
      // [feaas-role="ui"] acts as body, all body styles are set here
      '[feaas-role="ui"], :host': {
        lineHeight: 'base',
        bg: 'transparent',
        color: 'chakra-body-text',
        fontFamily: 'body',
        fontSize: 'md'
      },
      '[feaas-role="ui"] strong': {
        fontWeight: '600'
      }
    }
  },
  components: {
    Icon: {
      defaultProps: {
        boxSize: '6'
      }
    },
    Alert: {
      // color the AlertTitle/AlertDescription to neutral colors
      baseStyle: {
        title: {
          color: 'chakra-body-text'
        },
        description: {
          color: 'chakra-body-text'
        }
      },
      // Chakra toasts use alert with "solid" variant, which is too bright. This
      // maps solid variant to subtle, making toasts look the same as alerts
      variants: {
        solid: (props: any) => {
          return mergeDeep(props.theme.components.Alert.variants.subtle(props), {
            container: {
              maxWidth: '320px'
            }
          })
        }
      }
    },
    // Switch may lose focus ring on the second click, this forces the ring to always be visible
    Switch: {
      baseStyle: {
        track: {
          _focus: {
            boxShadow: 'var(--chakra-shadows-outline) !important'
          }
        }
      }
    },
    // Buttons in new theme dont have the focus ring
    Button: {
      baseStyle: {
        _focus: {
          boxShadow: 'var(--chakra-shadows-outline) !important'
        }
      },
      // Buttons in SDS had some sort of a looking style by default variant, so this adds a new one
      defaultProps: {
        variant: 'default'
      },
      variants: {
        default: {
          background: 'transparent',
          _hover: { bg: 'blackAlpha.100' },
          _active: { bg: 'blackAlpha.200' }
        },
        dialogPane: {
          rounded: 'lg',
          px: '1',
          flexDirection: 'column',
          height: 'auto',
          display: 'flex',
          alignItems: 'stretch',
          textAlign: 'left',
          whiteSpace: 'wrap',
          boxShadow: 'none',
          border: '1px solid',
          borderColor: 'blackAlpha.200',
          bg: 'white',
          p: 0,
          _hover: {
            background: 'blackAlpha.100',
            _disabled: {
              bg: 'white',
              boxShadow: 'none',
              borderColor: 'blackAlpha.200',
              cursor: 'default'
            },
            _active: {
              borderColor: 'primary.500'
            }
          },
          _active: {
            borderColor: 'primary.500',
            _hover: {
              bg: 'white'
            }
          },
          _disabled: {
            opacity: 1,
            cursor: 'pointer'
          }
        },
        dialogItem: {
          rounded: 'lg',
          px: '1',
          _hover: {
            bg: 'blackAlpha.100',
            _disabled: {
              bg: 'transparent'
            }
          },
          _active: { bg: 'blackAlpha.200' },
          _disabled: { opacity: 1, cursor: 'default' },
          py: 0,
          color: 'blackAlpha.500'
        },
        chrome: (props: BadgeProps) => {
          // unfortunately using isSelected makes react complain, as Chakra passes it to DOM element
          const { colorScheme, selected, size } = props
          return {
            _hover: {
              bg: colorScheme + '.100',
              color: colorScheme + '.800'
            },
            _active: {
              bg: colorScheme + '.100',
              color: colorScheme + '.800'
            }
          }
        }
      }
    },
    Tag: {
      baseStyle: {
        container: {
          userSelect: 'none',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          minWidth: '1px',
          display: 'inline-block',
          lineHeight: '24px'
        }
      }
    },
    Badge: {
      baseStyle: {
        fontSize: 'sm',
        lineHeight: '1.6',
        userSelect: 'none',
        textTransform: 'none',
        fontWeight: 500,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        minWidth: '1px',
        display: 'inline-block'
      },
      defaultProps: {
        colorScheme: 'blackAlpha'
      },

      variants: {
        clickable: (props: BadgeProps) => {
          // unfortunately using isSelected makes react complain, as Chakra passes it to DOM element
          const { colorScheme, selected, size } = props
          return {
            textTransform: 'none',
            fontWeight: '500',
            fontSize: size == 'sm' ? '12px' : '14px',
            lineHeight: size == 'sm' ? '18px' : '22px',
            variant: 'solid',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            _hover: {
              outlineStyle: 'solid'
            },
            _active: {
              outline: selected ? '1px solid' : null
            },
            outline: selected ? '1px solid' : null,
            outlineColor: `${colorScheme}.800`,
            bg: `${colorScheme}.100`,
            color: `${colorScheme}.800`
          }
        },
        datapath: (props: any) => ({
          ...defaultTheme.components.Badge.variants.subtle({
            colorScheme: 'green',
            ...props
          }),
          lineHeight: '1.6'
        })
      }
    },
    // Controversial: Makes focus ring on popover less random and jerky
    // When focus is inside the popover, it keeps a grey inactive-looking focus ring
    Popover: {
      defaultProps: {
        variant: 'default'
      },
      variants: {
        default: {
          content: {
            transition: 'box-shadow 0.2s',
            boxShadow: '0',
            _focusWithin: {
              transition: 'box-shadow 0.7s',
              boxShadow: '0 0 0 3px var(--chakra-colors-blackAlpha-200)',
              outline: '2px solid transparent',
              outlineOffset: '2px'
            },
            _focus: {
              transition: 'box-shadow 0.2s !important',
              boxShadow: 'var(--chakra-shadows-outline) !important',
              outline: '2px solid transparent',
              outlineOffset: '2px'
            }
          }
        }
      }
    },
    // This adds focus ring to the menu to look consistent with popover
    Menu: {
      defaultProps: {
        variant: 'default'
      },
      variants: {
        default: {
          list: {
            boxShadow: 'outline',
            transition: 'box-shadow 0.2s !important',
            _focusWithin: {
              boxShadow: 'outline',
              outline: '2px solid transparent',
              outlineOffset: '2px'
            }
          },
          divider: {
            borderBottomColor: 'blackAlpha.300'
          }
        },
        submenu: {
          list: {
            transition: 'box-shadow 0.2s !important',
            boxShadow: '0 0 0 3px var(--chakra-colors-blackAlpha-200)',
            _focusWithin: {
              boxShadow: 'outline',
              outline: '2px solid transparent',
              outlineOffset: '2px'
            }
          }
        }
      }
    },
    FormLabel: {
      variants: {
        subtle: {
          fontSize: 12,
          color: 'gray.700'
        }
      }
    },
    FormControl: {
      variants: {
        horizontal: {
          display: 'flex',
          alignItems: 'center',
          width: 'auto'
        }
      }
    },
    Link: {
      baseStyle: {
        color: 'gray.800',
        ':hover': {
          textDecoration: 'underline',
          color: 'primary.500'
        }
      }
    },
    Modal: {
      defaultProps: {
        isCentered: true,
        scrollBehavior: 'inside'
      },
      baseStyle: {
        dialog: { rounded: 'xl' },
        header: { py: '6', px: '8' },
        body: { px: '8', display: 'flex', minHeight: 1, width: '100%', alignItems: 'stretch' },
        footer: { py: '6', px: '6' },
        closeButton: { top: '3', insetEnd: '3' },
        overlay: {
          bg: 'blackAlpha.500'
        }
      }
    }
  }
}

function transformTheme(object: any, iterator: (arg: any) => any): any {
  const modified = iterator(object)
  if (modified !== undefined) {
    return modified
  }

  if (typeof object == 'string') {
    return object.replace(/(?:\d+?.\d+?|\d+)rem/g, (n) => parseFloat(n) * 16 + 'px')
  }
  if (Array.isArray(object)) {
    return object.map((v) => transformTheme(v, iterator))
  }
  if (object && typeof object == 'object') {
    var copy: any = {}
    for (var property in object) {
      // hack - default to light theme
      copy[property] = transformTheme(object[property], iterator)
    }
    return copy
  }
  return object
}

/**
 * Make _light values default, to fix chakra inside web component as it can't use themes because of how :host selectors
 * are generated.
 */
const sitecoreThemeWithLightAsDefault = transformTheme(sitecoreTheme, (object) => {
  if (object === 'primary.500') return '#5548d9'
  if (object?._light) {
    return object?._light
  }
}) as typeof sitecoreTheme

const combinedTheme = extendTheme(defaultTheme, sitecoreThemeWithLightAsDefault, FEAASStyles)
/**
 * Turn all rems into pixels, so that components embedded to external sites dont get affected by their layout font size
 * settings. It's a first and foremost fix for integrating with Pages, which sets its font-size to small value,
 * affecting all the web components
 */
const pixelizedTheme = transformTheme(combinedTheme, (object) => {
  if (typeof object == 'string') {
    return object.replace(/(?:\d+?.\d+?|\d+)rem/g, (n) => parseFloat(n) * 16 + 'px')
  }
}) as typeof combinedTheme
export default pixelizedTheme as ChakraTheme

import { Auth0Provider } from '@auth0/auth0-react'
import { AppState } from '@auth0/auth0-react/dist/auth0-provider.js'
import { ChakraProvider, ChakraProviderProps, ChakraTheme, ModalProps } from '@chakra-ui/react'
import createCache from '@emotion/cache'
import { CacheProvider } from '@emotion/react'
import {
  AuthModel,
  DatasourceModel,
  ExternalComponent,
  ExternalComponentModel,
  LibraryModel
} from '@sitecore-feaas/sdk'
import React, { Fragment, useMemo } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import type { CustomNavigateFunction } from '../../hooks/useWindowQueryUpdate.js'
import theme from '../../theme/index.js'
import Error from '../Error.js'
import MessageReceiverIframe from '../MessageReceiverIframe.js'
import ApmProvider from './ApmProvider.js'
import ConfirmationProvider from './ConfirmationProvider.js'
import { EnvironmentContext, EnvironmentName, environments, getEnvironment } from './EnvironmentProvider.js'
import LibraryProvider from './LibraryProvider.js'
import QueryStringProvider from './QueryStringProvider.js'
import SDKProvider, { SDKStatusCallback } from './SDKProvider.js'
import ByocRegistration from '../ByocRegistration.js'
export * from '../../exports.js'
export { AppContext }
export type { SDKStatusCallback }

interface FEAASContextProps {
  libraryId: LibraryModel['id']
  children?: any
  container?: HTMLElement | ShadowRoot
  backend?: string
  onStatusChange?: SDKStatusCallback
  auth?: Partial<AuthModel>
  navigate?: (url: string, options?: object) => void
  chakraProps?: ChakraProviderProps
  modalProps?: Partial<ModalProps>
  getTheme?: (theme: ChakraTheme) => ChakraTheme
  environment?: EnvironmentName
  handleErrors?: boolean
}

export default function AppContext({
  children,
  libraryId,
  auth,
  onStatusChange,
  container,
  backend,
  navigate = (url: string, options?: object) => {},
  chakraProps,
  modalProps,
  environment = getEnvironment(),
  getTheme = (theme) => theme,
  handleErrors
}: FEAASContextProps) {
  const chakraCache = useMemo(
    () =>
      /*@ts-ignore TS 4.9 FIXME*/
      createCache({
        key: 'feaas',
        container: container || document.head
      }),
    [container]
  )
  const onRedirectCallback = (appState: AppState) => {
    navigate(appState.returnTo, { replace: true })
  }
  const env = environments[environment]
  const Boundary = handleErrors ? ErrorBoundary : Fragment
  const BoundaryProps = handleErrors ? { FallbackComponent: Error } : undefined

  return (
    <EnvironmentContext.Provider value={env}>
      <ApmProvider environment={environment}>
        <Auth0Provider
          domain={env.auth0Domain}
          clientId={env.auth0ClientId}
          audience={env.auth0Audience}
          redirectUri={`${window.location.origin}${window.location.search}`}
          useRefreshTokens={true}
          cacheLocation={'localstorage'}
          onRedirectCallback={onRedirectCallback}
        >
          <CacheProvider value={chakraCache}>
            <QueryStringProvider navigate={navigate}>
              <ChakraProvider theme={getTheme(theme)} {...chakraProps}>
                <Boundary {...BoundaryProps}>
                  <SDKProvider
                    auth={auth}
                    backend={backend}
                    onStatusChange={onStatusChange}
                    navigate={navigate as any as CustomNavigateFunction}
                  >
                    <LibraryProvider libraryId={libraryId}>
                      <ConfirmationProvider {...modalProps}>
                        {children}
                        <ByocRegistration />
                      </ConfirmationProvider>
                    </LibraryProvider>
                  </SDKProvider>
                </Boundary>
              </ChakraProvider>
            </QueryStringProvider>
          </CacheProvider>
        </Auth0Provider>
      </ApmProvider>
    </EnvironmentContext.Provider>
  )
}
